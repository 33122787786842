import './App.css';
import {FirstPage} from './firstPage';

function App() {
  return (
    <div>
      <FirstPage/>
    </div>
  );
}

export default App;
